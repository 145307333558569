import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router';
import {HomePage} from "./HomePage";
import {BrowserRouter} from "react-router-dom";
import {NavBar} from "./NavBar";
import {InvoiceWorkflowPage} from "./Pages/InvoiceWorkflow/InvoiceWorkflowPage";
import {BaseService, ErrorInfo} from "./Services/BaseService";
import {Colors} from "./Colors";
import {CallbackHistory} from "./Pages/ApiAccess/CallbackHistory";
import {UserManagement} from "./Pages/ApiAccess/UserManagement";
import {Login} from "./PublicPages/Login";
import {Signup} from "./PublicPages/Signup";
import {ResetPassword} from "./PublicPages/ResetPassword";
import {PacksizeMerge} from "./Pages/Maintenance/PacksizeMerge"
import {InvoiceViewer} from './Pages/Maintenance/InvoiceViewer';
import {AttachmentGroupingUploadPage} from './Pages/Maintenance/AttachmentGroupingUploadPage';
import {WorkflowContextProvider} from "./Contexts/WorkflowContext";
import { UserTimeTracking } from './Pages/Maintenance/UserTimeTracking';
import {ManageSupplierPage} from "./Pages/Maintenance/ManageSupplier";
import {WorkflowViewer} from "./Pages/Maintenance/WorkflowViewer";
import {AdminTasks} from "./Pages/Maintenance/AdminTasks";
import {MultipleInvoiceErrorQueue} from "./Pages/Maintenance/MultipleInvoiceErrorQueue/MultipleInvoiceErrorQueue";
import WorkflowHistory from "./Pages/Maintenance/WorkflowHistory/WorkflowHistory";
import {ManageRestaurantSupplierPage} from "./Pages/Maintenance/ManageRestaurantSupplier";
import {
    RestaurantSupplierHouseWorkflowPage
} from "./Pages/RestaurantSupplierHouseWorkflow/RestaurantSupplierHouseWorkflowPage";
import {ParsleyMapping} from "./Pages/Maintenance/ParsleyMapping";

export function App() {
    const [errorInfo, setErrorInfo] = useState<ErrorInfo>();
    useEffect(() => {
        BaseService.addErrorListener(setErrorInfo);
    }, []);
    return <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signUp" element={<Signup/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path="*" element={<ElementWrapperForRoute/>}/>
        </Routes>
        {errorInfo && <div style={{position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: Colors.white, display: 'grid', justifyContent: 'center', alignContent: 'center'}}>
            <div>
                There was an error with request: {errorInfo.requestUrl}
                <button onClick={() => setErrorInfo(undefined)}> Close <i style={{fontSize: 22}} className="material-icons">cancel</i></button>
            </div>
            <div>
                Code: {errorInfo.code}
            </div>
            <div>
                Code Name: {errorInfo.codeName}
            </div>
            <div style={{display: 'grid'}}>
                Message:
                <pre style={{overflow: "auto"}}>{errorInfo.msg}</pre>
            </div>
        </div>}
    </BrowserRouter>
}

function ElementWrapperForRoute(){
    return <WorkflowContextProvider>
        <div style={{gridTemplateAreas: "'nav' 'content'", gridTemplateRows: "auto 1fr", display: "grid", minHeight: 0, height: '100%'}}>
            <NavBar/>
            <div style={{gridArea: "content", display: "grid", minHeight: 0}}>
                <Routes>
                    <Route path="/invoiceWorkflow/:stage/:level" element={<InvoiceWorkflowPage/>}/>
                    <Route path="/restaurantSupplierHouseWorkflow" element={<RestaurantSupplierHouseWorkflowPage/>}/>
                    <Route path="/callbacks" element={<CallbackHistory/>} />
                    <Route path="/packsizeMerge" element={<PacksizeMerge/>}/>
                    <Route path="/parsleyMapping" element={<ParsleyMapping/>}/>
                    <Route path="/invoiceEntry" element={<AttachmentGroupingUploadPage/>}/>
                    <Route path="/invoiceViewer/:invoiceId" element={<InvoiceViewer/>}/>
                    <Route path="/userTimeTracking" element={<UserTimeTracking/>}/>
                    <Route path="/users" element={<UserManagement/>}/>
                    <Route path="/viewWorkflow/:workflowId" element={<WorkflowViewer/>}/>
                    <Route path="/viewWorkflow" element={<WorkflowViewer/>}/>
                    <Route path="/manageSupplier/:supplierId" element={<ManageSupplierPage/>}/>
                    <Route path="/manageSupplier" element={<ManageSupplierPage/>}/>
                    <Route path="/manageRestaurantSupplier/:restaurantId" element={<ManageRestaurantSupplierPage/>}/>
                    <Route path="/manageRestaurantSupplier" element={<ManageRestaurantSupplierPage/>}/>
                    <Route path="/adminTasks" element={<AdminTasks/>}/>
                    <Route path="/multipleInvoiceErrorQueue" element={<MultipleInvoiceErrorQueue/>}/>
                    <Route path="/workflowHistory" element={<WorkflowHistory/>}/>
                    <Route path="/" element={<HomePage/>}/>
                </Routes>
            </div>
        </div>
    </WorkflowContextProvider>
}