import {AbstractDomainObject} from "./AbstractDomainObject";
import moment from "moment/moment";
import {Attachment} from "./Workflow/Workflow";

export class ParsleySupplier extends AbstractDomainObject{
    id!: string;
    createdTime!: moment.Moment;
    supplierId!: string;
    supplierName!: string;
    attachmentGroupingId!: string;
    mappedToSupplierId?: string;
    mappedTime?: string;
    mappedBy?: string;
    attachments!: Attachment[];
}