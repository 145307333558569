import {Supplier, SupplierHouse} from "../Domain/Supplier";
import {BaseService} from "./BaseService";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import { ItemCodeWithDesc, PackSize } from "../Domain/PackSize";
import { Uom } from "../Domain/UOMService";
import {PlatformSupplier} from "../Domain/PlatformSupplier";
import {Address} from "../Domain/Address";
import {ParsleySupplier} from "../Domain/ParsleySupplier";

export class SupplierService extends BaseService{
    public static async searchSuppliers(searchTerm: string, region: string, restId?: string, includeHouses = false, searchLimit: number = 25): Promise<Supplier[]> {
        let url = `api/internalsupplier?searchString=${encodeURIComponent(searchTerm)}`;
        if(restId){
            url += "&restaurantId="+encodeURIComponent(restId);
        }
        url += `&includeHouses=${includeHouses}`;
        url += `&limit=${searchLimit}`;
        url += `&region=${region}`;
        return this.getToArray(url, Supplier);
    }
    
    static async searchItemCode(supplierId: string, searchTerm: string): Promise<ItemCodeWithDesc[]> {
        const response = await this.get(`api/internalsupplier/${encodeURIComponent(supplierId)}/itemCodes/?searchString=${encodeURIComponent(searchTerm)}`);
        return await response!.json();
    }

    static async searchItemDesc(supplierId: string, searchTerm: string): Promise<ItemCodeWithDesc[]> {
        const response = await this.get(`api/internalsupplier/${encodeURIComponent(supplierId)}/desc/?searchString=${encodeURIComponent(searchTerm)}`);
        return await response!.json();
    }

    static async getPacksizes(supplierId: string, itemCode: string): Promise<PackSize[]> {
        const response = await this.get(`api/internalsupplier/${encodeURIComponent(supplierId)}/packSizes/?itemCode=${encodeURIComponent(itemCode)}`);
        return await response!.json();
    }

    static async updateProductInfo(supplierId: string, oldItemCode: string, newItemCode: string, desc: string, isCatchWeight: boolean): Promise<any> {
         return await this.put(`api/internalsupplier/${encodeURIComponent(supplierId)}/packSizes/updateProductInfo/?itemCode=${encodeURIComponent(oldItemCode)}&newItemCode=${encodeURIComponent(newItemCode)}&desc=${encodeURIComponent(desc)}&isCatchWeight=${encodeURIComponent(isCatchWeight)}`);
    }

    static async mergePackSizes(fromPackSizeId:string, toPackSizeId:string): Promise<any> {
        return await this.post(`api/internalsupplier/packSizes/merge/?fromPackSizeId=${encodeURIComponent(fromPackSizeId)}&toPackSizeId=${encodeURIComponent(toPackSizeId)}`);
    }

    static async updatePackSize(packSizeId: string, pack: number, size:number, uom:Uom): Promise<any> {
        return await this.post(`api/internalsupplier/packSizes/update/?packSizeId=${encodeURIComponent(packSizeId)}&pack=${pack}&size=${size}&uom=${uom}`);
    }

    static async searchPlatformSuppliers(searchTerm: string): Promise<PlatformSupplier[]> {
        return await this.getRawJson(`api/internalsupplier/rootSupplierHouse?searchString=${encodeURIComponent(searchTerm)}`);
    }

    static async setPlatformSupplier(parentPlatformId: string, supplier: Supplier) {
        return await this.postToArray(`api/internalsupplier/${encodeURIComponent(supplier.id)}/setPlatformSupplier/${encodeURIComponent(parentPlatformId)}`, SupplierHouse);
    }

    static getById(supplierId: string) {
        return this.getToClass(`api/internalsupplier/${encodeURIComponent(supplierId)}`, Supplier);
    }

    static async saveAddressForSupplier(supplier: Supplier) {
        return this.putToClass(`api/internalsupplier/${encodeURIComponent(supplier.id)}/address`, supplier, supplier.toDTO());
    }

    static createNewHouse(supplier: Supplier, addressObj: Address) {
        return this.post(`api/internalsupplier/${encodeURIComponent(supplier.id)}/house`, addressObj);
    }

    static setSupplierType(supplierId: string, supplierType: string) {
        return this.putToClass(`api/internalsupplier/${encodeURIComponent(supplierId)}/type`, Supplier, supplierType);
    }
    
    static getParsleySuppliers(){
        return this.getToArray(`api/internalSupplier/parsleySuppliers`, ParsleySupplier);
    }

    static async saveParsleyMapping(supplierId: string, mappingId: string) {
        await this.post(`api/internalSupplier/parsleySupplier/${encodeURIComponent(mappingId)}/${encodeURIComponent(supplierId)}`);
    }
}

export function useSupplier(supplierOrId?: Supplier | string) {
    const [supplier, setSupplier] = useState<Supplier | null>(null);
    
    useEffect(() => {
        if (supplierOrId === supplier || supplierOrId === supplier?.id) {
            return;
        }
            
        if (supplierOrId instanceof Supplier) {
            setSupplier(supplierOrId);
        } else if (typeof supplierOrId === "string") {
            SupplierService.getById(supplierOrId).then(supplier => {
                setSupplier(supplier);
            });
        }
    }, [supplierOrId]);
    
    return [supplier, setSupplier] as [Supplier | null, Dispatch<SetStateAction<Supplier | string | null | undefined>>];
}