import React, {useEffect, useState} from "react";
import {DangerButton, SaveButton} from "../../Components/Buttons";
import {ParsleySupplier} from "../../Domain/ParsleySupplier";
import {SupplierSearch} from "../../Components/SupplierSearch";
import {Supplier} from "../../Domain/Supplier";
import {SupplierService} from "../../Services/SupplierService";
import {Css} from "../../Css";
import {AttachmentPicture} from "../InvoiceWorkflow/InvoiceWorkflowPage";
import {Work} from "@mui/icons-material";

export function ParsleyMapping() {
    const [parsleyMappings, setParsleyMappings] = useState<ParsleySupplier[]>([]);
    const [supplier, setSupplier] = useState<Supplier>()
    const [workingMapping, setWorkingMapping] = useState<ParsleySupplier>();
    const [rotation, setRotation] = useState(0);
    
    const loadData = async () => {
        const parSuppliers = await SupplierService.getParsleySuppliers();
        setParsleyMappings(parSuppliers);
        setWorkingMapping(parSuppliers[0]);
    }

    useEffect(() => {
        loadData();
    }, []);

    const saveFn = async () => {
        if(!supplier){
            alert("Supplier not selected");
            return;
        }
        await SupplierService.saveParsleyMapping(supplier!.id, workingMapping!.id);
        loadData();
    }
    
    if(!workingMapping){
        return <div>
            No more mappings. All done, good job
        </div>
    }
    
    return <div style={Css.columns('300px 1fr', 5)}>
        <div>
            {<div>
                Parsley Supplier and id:
                <div>
                    {workingMapping.supplierName} ({workingMapping.supplierId})
                </div>
            </div>
            }
            <div>
                <div>
                    Search for existing supplier to map to: 
                </div>
                <SupplierSearch onChange={setSupplier} supplier={supplier} region={"USA"}></SupplierSearch>
            </div>
            <SaveButton onClick={saveFn}>Save Parsley Supplier {workingMapping?.supplierName} to OCR Supplier {supplier?.name}</SaveButton>
        </div>
        <div>
            {workingMapping.attachments.map(x => <AttachmentPicture attachment={x}
                                                     key={"attachment" + x.externalId}
                                                     rotation={rotation}
                                                     rotationChanged={() => setRotation(0)}
                                                     zoomPercentage={0}/>)}
        </div>
    </div>
}
