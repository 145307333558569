import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {WorkflowService} from "../../Services/WorkflowService";
import {Attachment, WorkflowStage, WorkflowStagesThatCouldShowSingleAttachments} from "../../Domain/Workflow/Workflow";
import {SupplierEntrySideBar} from "./SupplierEntrySideBar";
import {ItemCodeAndPriceSidebar} from "./ItemCodeAndPriceSidebar";
import {PackSizeEntrySidebar} from "./PackSizeEntrySidebar";
import {NewSupplierSidebar} from "./NewSupplierSidebar";
import {ErrorSidebar} from "./ErrorSideBar";
import {SidebarHeader} from "./WorkflowCommonComponents";
import {PictureIssueSidebar} from "./PictureIssueSidebar";
import {SaveButton} from "../../Components/Buttons";
import {WorkflowContext} from "../../Contexts/WorkflowContext";
import {Colors} from "../../Colors";
import {ConfirmCategoryOnlySidebar} from "./ConfirmCategoryOnlySidebar";
import {GlCodeAndCatOnlySidebar} from "./GlCodeAndCatOnlySidebar";
import {UserService} from "../../Services/UserService";

type InvoiceWorkflowParams = {
    stage: WorkflowStage;
    level: string;
}

export function InvoiceWorkflowPage() {
    const {setStage, workflow, forcePullSpecificWorkflow, currentAttachment} = useContext(WorkflowContext);
    const {stage, level} = useParams<InvoiceWorkflowParams>();
    useEffect(() => {
        setStage(stage as any, +(level || "0"));
    }, [stage, level, setStage])

    useEffect(() => {
        setZoomPercent(100);
        setRotation(0);
    }, [workflow])

    useEffect(() => {
        const listenerFn = (event: KeyboardEvent) => {
            if ((event.key.toLowerCase() === 't' && event.altKey && event.ctrlKey && event.shiftKey) || (event.key.toLowerCase() === '+' && event.shiftKey && UserService.user.isSuperAdmin)) {
                let result = prompt("Enter workflow id, can be full id or just ending number and letter, e.g. InvoiceWorkflows/337826-A or 337826-A");
                if (result) {
                    result = result.trim().replaceAll(`"`, '');
                    if (result.toLowerCase().indexOf('invoiceworkflows') === -1) {
                        result = `InvoiceWorkflows/${result}`
                    }
                    forcePullSpecificWorkflow(result, stage as any);
                }
            }
        }
        window.addEventListener('keydown', listenerFn)

        return () => {
            window.removeEventListener('keydown', listenerFn);
        }
    }, [stage])

    const [rotation, setRotation] = useState<number>(0);
    const [zoomPercent, setZoomPercent] = useState<number>(100);
    const keyDownHandler = (event: KeyboardEvent) => {
        let handled = false;
        if (event.altKey && event.key === "ArrowRight") {
            setRotation(x => x + 90);
            handled = true;
        } else if (event.altKey && event.key === "ArrowLeft") {
            setRotation(x => x - 90);
            handled = true;
        }
        if (event.altKey && event.key === "+") {
            setZoomPercent(x => x + 10);
            handled = true;
        }
        if (event.altKey && event.key === "-") {
            setZoomPercent(x => x - 10);
            handled = true;
        }
        if (handled) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    if (workflow === undefined || WorkflowStagesThatCouldShowSingleAttachments.includes(stage!) && currentAttachment === undefined) {
        return <div>
            Loading...
        </div>
    }
    if (workflow === null) {
        return <div>
            No more workflows for {stage}
        </div>
    }

    if (WorkflowStagesThatCouldShowSingleAttachments.includes(stage!) && workflow.shouldProcessAsMultiInvoice && currentAttachment === null) {
        return <div>
            No more attachments for {stage}
        </div>
    }

    return <div style={{
        display: 'grid',
        gridTemplateAreas: "'sideBar mainArea'",
        gridTemplateColumns: '500px auto',
        minHeight: 0
    }}>
        <div style={{minHeight: 0, display: 'grid', gridTemplateRows: "auto 1fr"}}>
            <div style={{marginBottom: 10}}>
                <SidebarHeader/>
            </div>
            {stage === "SupplierEntry" && <SupplierEntrySideBar/>}
            {stage === "MISupplierEntry" && <SupplierEntrySideBar/>}
            {stage === "ItemCodeAndPrice" && <ItemCodeAndPriceSidebar/>}
            {stage === "GlCode" && <GlCodeAndCatOnlySidebar/>}
            {stage === "CategoryOnly" && <GlCodeAndCatOnlySidebar/>}
            {stage === "PackSize" && <PackSizeEntrySidebar/>}
            {stage === "NewSupplier" && <NewSupplierSidebar/>}
            {stage === "Error" && <ErrorSidebar/>}
            {stage === "PictureIssue" && <PictureIssueSidebar/>}
            {stage === "VerifyCategoryOnly" && <ConfirmCategoryOnlySidebar/>}
        </div>
        <div style={{gridArea: "'mainArea'", minHeight: 0, overflowY: "auto", padding: 10, height: '100%'}}>
            <div style={{height: '100%'}}>
                <InfoSection display={workflow.id} label="ID"/>
                <InfoSection display={workflow.supplierName} label="Supplier"/>
                <InfoSection display={workflow.invoiceNumber} label="Invoice Number"/>
                <InfoSection display={workflow.deliveryDate?.format("YYYY-MM-DD")} label="Delivery Date"/>
                <InfoSection display={`${workflow.restaurantName} (${workflow.restaurantIdForCustomer})`}
                             value={workflow.restaurantIdForCustomer} label="Restaurant (ID)"/>
                <InfoSection display={`${workflow.tenantName} (${workflow.tenantId})`} value={workflow.tenantId}
                             label="Tenant (ID)"/>
                <InfoSection display={`${workflow.region}`} label="Region"/>
                {workflow.customerNotes && <div style={{padding: 10, backgroundColor: Colors.attn}}>
                    On {workflow.customerNotesTime?.format("YYYY-MM-DD")} Customer said
                    <div>
                        {workflow.customerNotes}
                    </div>
                </div>}
                <div style={{height: '100%'}}>
                    {WorkflowStagesThatCouldShowSingleAttachments.includes(stage!) && workflow.shouldProcessAsMultiInvoice
                        ? <AttachmentPicture attachment={currentAttachment!}
                                             key={"attachment" + currentAttachment!.externalId} rotation={rotation}
                                             rotationChanged={() => setRotation(0)} zoomPercentage={zoomPercent}/>
                        : workflow.attachments.map(x => <AttachmentPicture attachment={x}
                                                                           key={"attachment" + x.externalId}
                                                                           rotation={rotation}
                                                                           rotationChanged={() => setRotation(0)}
                                                                           zoomPercentage={zoomPercent}/>)
                    }
                </div>
            </div>
        </div>
    </div>
}

function InfoSection({value, display, label}: { value?: string, display?: string, label?: string }) {
    return <span style={{margin: "0 5px", cursor: "pointer"}}
                 onClick={() => navigator.clipboard.writeText(value || display || '')}>
        <label style={{fontWeight: "bold", margin: "0 5px"}}>{label}</label>
        <span id={`info-section-${label}`}>{display}</span>
    </span>
}


export function AttachmentPicture(props: { attachment: Attachment, rotation: number, rotationChanged: () => void, zoomPercentage: number, 
    customStyle?: React.CSSProperties, handleAttachmentClick?: (attachment: Attachment) => void }) {
    const {attachment, rotation, rotationChanged, zoomPercentage, customStyle, handleAttachmentClick} = props;
    const isPdf = WorkflowService.isPdf(attachment);
    const imgRef = useRef<HTMLImageElement>(null);
    const [imgSource, setImgSource] = useState<string>(WorkflowService.getImgSource(attachment));
    const [imgCacheIndex, setImgCacheIndex] = useState<number>(0);

    const [origWidthInPixels, setOrigWidthInPixels] = useState<number>(0);
    const [origHeightInPixels, setOrigHeightInPixels] = useState<number>(0);


    const [widthInPixels, setWidthInPixelsToUse] = useState<number>(origWidthInPixels);
    const [heightInPixels, setHeightInPixelsToUse] = useState<number>(origHeightInPixels);
    const [marginTop, setMarginTop] = useState<any>()
    const [marginLeft, setMarginLeft] = useState<any>()
    const [heightToUse, setHeightToUse] = useState<any>("auto")
    const [widthToUse, setWidthToUse] = useState<any>("100%")
    const [zoomChanged, setZoomChanged] = useState(false)
    const [layoutChanged, setLayoutChanged] = useState(false)
    
    useEffect(() => {
        if (imgRef.current?.height && !origHeightInPixels) {
            setOrigHeightInPixels(imgRef.current?.height ?? 0);
            setHeightInPixelsToUse(imgRef.current?.height ?? 0);

        }
        if (imgRef.current?.width && !origWidthInPixels) {
            setOrigWidthInPixels(imgRef.current?.width ?? 0);
            setWidthInPixelsToUse(imgRef.current?.width ?? 0);
        }
        setZoomChanged(!zoomChanged);

    }, [zoomPercentage]);

    useEffect(() => {
        if (imgRef.current?.height && !origHeightInPixels) {
            setOrigHeightInPixels(imgRef.current?.height ?? 0);
            setHeightInPixelsToUse(imgRef.current?.height ?? 0);
        }

        if (imgRef.current?.width && !origWidthInPixels) {
            setOrigWidthInPixels(imgRef.current?.width ?? 0);
            setWidthInPixelsToUse(imgRef.current?.width ?? 0);
        }

        if (widthInPixels && heightInPixels) {

            let marginAdjust = 1.0;
            marginAdjust = Math.abs(widthInPixels - heightInPixels) / 2;

            let thisRotation = Math.abs(rotation % 360);

            if (widthInPixels > heightInPixels) {
                setMarginTop((thisRotation == 90 || thisRotation == 270) ? (marginAdjust) + 'px' : 'auto');
                setMarginLeft((thisRotation == 90 || thisRotation == 270) ? '-' + (marginAdjust) + 'px' : 'auto');
            } else {
                setMarginTop((thisRotation == 90 || thisRotation == 270) ? '-' + (marginAdjust) + 'px' : 'auto');
                setMarginLeft((thisRotation == 90 || thisRotation == 270) ? (marginAdjust) + 'px' : 'auto');
            }
        }
    }, [rotation, layoutChanged]);

    useEffect(() => {
        if (origWidthInPixels && origHeightInPixels) {
            setWidthInPixelsToUse(origWidthInPixels * (zoomPercentage / 100));
            setHeightInPixelsToUse(origHeightInPixels * (zoomPercentage / 100));
            setLayoutChanged(!layoutChanged);
        }
    }, [zoomChanged]);

    useEffect(() => {
        if (origHeightInPixels && origWidthInPixels) {
            if (widthInPixels > heightInPixels) {
                setWidthToUse((rotation == 90 || rotation == -90) ? 'auto' : (widthInPixels) + 'px');
                setHeightToUse((rotation == 90 || rotation == -90) ? (heightInPixels) + 'px' : 'auto');

            } else {
                setHeightToUse((rotation == 90 || rotation == -90) ? (heightInPixels) + 'px' : 'auto');
                setWidthToUse((rotation == 90 || rotation == -90) ? 'auto' : (widthInPixels) + 'px');

            }
            setLayoutChanged(!layoutChanged);
        }

    }, [widthInPixels, heightInPixels, rotation]);

    useEffect(() => {
        setImgSource(WorkflowService.getImgSource(attachment, imgCacheIndex));
    }, [imgCacheIndex, attachment]);

    const saveRotation = async () => {
        await WorkflowService.updateImgRotation(attachment.externalId, rotation);
        rotationChanged();
        setImgCacheIndex(imgCacheIndex + 1);
        setOrigWidthInPixels(0);
        setOrigHeightInPixels(0);
    };
    
    if (isPdf) {
        return <embed style={{width: '100%', height: '100%'}} src={imgSource} type="application/pdf"/>
    }
    
    return <div style={customStyle}>
        {rotation != 0 && <div>
            <SaveButton onClick={saveRotation}>Save Rotation</SaveButton>
        </div>}
        <img src={imgSource}
             ref={imgRef}
             alt={'missing or bad image'}
             onClick={() => handleAttachmentClick ? handleAttachmentClick(attachment) : null}
             style={{
                 transform: 'rotate(' + rotation + "deg)",
                 height: heightToUse,
                 width: widthToUse,
                 marginLeft: marginLeft,
                 marginTop: marginTop,
                 marginBottom: marginTop,
                 display: 'block',
             }}/>
    </div>
}